import { devices } from "@/utils/breakpoints"

import { useTranslation } from "next-i18next"
import styled from "styled-components"

import Plan from "./plan"

const PackagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  padding: 48px 16px;

  @media ${devices.tablet} {
    padding: 48px 28px 20px 28px;
  }

  // background-color: var(--color-background);
  width: var(--w-screen);
`

const Packages = () => {
  const { t } = useTranslation()

  return (
    <PackagesContainer id="pricing">
      {/* <h3 className="w-full text-center text-[24px] font-700 text-blue-900 tablet:text-[32px]">
        {t("txt_pricing_title")}
      </h3> */}
      <h3 className="whitespace-pre-line text-center text-[26px] font-800 leading-[1.1] text-blue-900 tablet:text-[32px] desktop:text-[52px]">
        <span>{t("txt_choose_plan")}</span>
        <span className=" text-color-chat">
          {t("txt_choose_plan_free")}
        </span>
      </h3>
      <Plan />
    </PackagesContainer>
  )
}

export default Packages
