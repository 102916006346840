import { ErrorMessage } from "@/comps/message"
import {
  PaymentSessionData,
  PAYMENT_DATA_KEY,
  useAdjustLogger,
} from "@/utils/adjust"
import useAuth from "@/utils/client-auth"
import { NotificationContext } from "@/utils/notification"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import {
  PaymentStripeUrlRequest,
  PaymentStripeUrlResponse,
} from "pages/api/payment-stripe-url"
import { useContext, useState } from "react"
import { v4 } from "uuid"

import {
  getAdId,
  getCurrentPackId,
  pricesSchema,
} from "@/comps/subscription-popup"
import { SquareSwitch } from "@/comps/toggle"
import { redirectToLogin } from "@/utils/login-redirect"
import RenderBlock from "sections/pricing/render-block"
import priceJson from "statics/offering-prices.json"

const prices = pricesSchema.parse(priceJson)

const Plan = () => {
  const { userInfo } = useAuth()

  const { packId: currentPackId, isYearly: isPackYearly } =
    getCurrentPackId(userInfo.entitlements)

  const [isYearly, setIsYearly] = useState(isPackYearly)

  const currentValue =
    prices.plans.advanced.variants?.findIndex(
      (a) => a.pack_id === currentPackId
    ) ?? -1

  const [packIndex, setPackIndex] = useState<number>(
    currentValue !== -1 ? currentValue : 0
  )

  const [packId, setPackId] = useState(
    prices.plans.advanced.variants?.[packIndex].pack_id ??
      ""
  )

  const { t } = useTranslation("common")

  const router = useRouter()

  const { notify } = useContext(NotificationContext)

  async function handlePurchaseClick() {
    if (!userInfo.isAnonymous) {
      fetchPaymentLink()
    } else {
      redirectToLogin()
    }
  }

  const logAdjust = useAdjustLogger()

  async function fetchPaymentLink() {
    try {
      const sessionId = v4()
      const payload: PaymentStripeUrlRequest = {
        location: router.asPath,
        period: isYearly ? "yearly" : "monthly",
        adId: await getAdId(),
        sessionId,
        offering: packId,
      }

      const { url, currency, value } = await axios
        .post<PaymentStripeUrlResponse>(
          "/api/payment-stripe-url",
          payload
        )
        .then((res) => res.data)

      logAdjust?.logEvent("initiate_checkout")

      const paymentData: PaymentSessionData = {
        currency,
        value,
        session: sessionId,
      }

      localStorage.setItem(
        PAYMENT_DATA_KEY,
        JSON.stringify(paymentData)
      )

      router.push(url)
    } catch (error) {
      console.error(error)
      notify(
        <ErrorMessage>
          {t("txt_could_not_purchase_now")}
        </ErrorMessage>
      )
    }
    window.blur()
  }

  return (
    <div
      className={clsx("flex w-full flex-col items-center")}>
      <div className=" flex gap-[10px]">
        <span
          className={clsx(
            isYearly ? "text-blue-500" : "text-blue-900",
            "text-[18px] font-500 transition-colors"
          )}>
          {t("txt_monthly")}
        </span>
        <SquareSwitch
          onChange={setIsYearly}
          enabled={isYearly}
        />
        <span
          className={clsx(
            isYearly ? "text-blue-900" : "text-blue-500",
            "text-[18px] font-500 transition-colors"
          )}>
          {t("txt_yearly")}
        </span>
        <span
          className={clsx(
            "text-[18px] font-500 transition-colors",
            isYearly ? "text-blue-900" : "text-blue-500"
          )}>
          {t("fs_save_percent", {
            percent: String(prices.save_percent),
          })}
        </span>
      </div>

      <RenderBlock
        plans={prices.plans}
        isYearly={isYearly}
        handlePurchaseClick={handlePurchaseClick}
        setPackId={setPackId}
        setPackIndex={setPackIndex}
        packIndex={packIndex}
      />

      <p
        className={clsx(
          " pt-4 text-[16px] font-400 text-blue-500 transition-opacity",
          isYearly ? "opacity-100" : "opacity-0"
        )}>
        *{t("txt_billed_yearly")}
      </p>
    </div>
  )
}

export default Plan
