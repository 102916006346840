import Bubble from "@/comps/bubble"
import Text, { RawText } from "@/comps/text"

import {
  Desktop,
  Mobile,
  Tablet,
} from "@/utils/breakpoints"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { FlexBox, FlexItem } from "react-styled-flex"

import styled from "styled-components"

const Possibility = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  flex: 1;

  & + & {
    border-left: solid 1px var(--color-separator);
  }
`

const values = {
  customers: "2.5X",
  spend: "10X",
  time: "5X",
}

interface AchievementProps {
  type: "customers" | "spend" | "time"
  tablet?: boolean
}

const Achievement = (props: AchievementProps) => {
  const { type, tablet } = props

  const i18nKey = `txt_marketplace_${type}_`

  const action = i18nKey + "action"
  const value = values[type]
  const unit = i18nKey + "unit"

  return (
    <Possibility>
      <FlexItem box shrink={0} paddingTop={7}>
        <Text
          content={action}
          size="18"
          weight="700"
          capitalization="uppercase"
        />
      </FlexItem>
      <FlexItem box shrink={0}>
        <RawText
          content={value}
          size={tablet ? "32" : "36"}
          weight="800"
        />
      </FlexItem>
      <FlexItem box shrink={0} paddingBottom={7}>
        <Text
          content={unit}
          size="16"
          weight="400"
          color="600"
          capitalization="capitalize"
        />
      </FlexItem>
    </Possibility>
  )
}

const PossibilityMobile = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  flex: 1;

  & + & {
    border-top: solid 1px var(--color-separator);
  }
`

const AchievementMobile = (props: AchievementProps) => {
  const { type } = props

  const i18nKey = `txt_marketplace_${type}_`

  const action = i18nKey + "action"
  const value = values[type]
  const unit = i18nKey + "unit"

  return (
    <PossibilityMobile>
      <FlexBox column margin={20}>
        <FlexItem box shrink={0}>
          <Text
            content={action}
            size="18"
            weight="700"
            capitalization="uppercase"
          />
        </FlexItem>
        <FlexItem box shrink={0}>
          <Text
            content={unit}
            size="16"
            weight="400"
            color="600"
            capitalization="capitalize"
          />
        </FlexItem>
      </FlexBox>
      <FlexItem box shrink={0} margin={20}>
        <RawText content={value} size="28" weight="800" />
      </FlexItem>
    </PossibilityMobile>
  )
}

const ContainerDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  align-items: stretch;

  width: 1000px;
  height: 144px;

  border-radius: 24px;

  background-color: var(--color-background-cell);
`

const ContainerTablet = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  align-items: stretch;

  width: 728px;
  height: 144px;

  border-radius: 24px;

  background-color: var(--color-background-cell);
`

const ContainerMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  align-items: stretch;

  width: calc(var(--w-screen) - 32px);
  margin: 0 16px;

  border-radius: 12px;

  background-color: var(--color-background-cell);
`

export function NewAchievements() {
  const { t } = useTranslation()
  return (
    <div className="tablet: flex w-full flex-col items-center justify-center gap-4 py-[40px] desktop:py-[60px]">
      <div className="flex items-center justify-center">
        <Bubble
          text="You are in the right place"
          color="pinkPurpleLight"
        />
      </div>
      <div
        className={clsx(
          "my-4 flex w-[calc(100%-32px)] flex-col flex-wrap items-stretch rounded-[12px] bg-blue-cell",
          "tablet:h-[144px] tablet:w-[728px] tablet:flex-row tablet:px-0",
          "desktop:w-[888px]"
        )}>
        <div className="flex flex-1 flex-row items-center justify-between p-5 tablet:flex-col tablet:justify-center tablet:p-0">
          <div className="flex flex-col justify-center tablet:items-center">
            <p className="text-[18px] font-700 text-blue-600">
              {t("txt_more_sales")}
            </p>
            <p className="hidden text-[36px] font-700 text-blue-700 tablet:block">
              80%
            </p>
            <p className="text-[16px] font-400 text-blue-500">
              {t("txt_higher_conversion")}
            </p>
          </div>
          <p className="text-[28px] font-700 text-blue-700 tablet:hidden">
            80%
          </p>
        </div>
        <div className="flex flex-1 flex-row items-center justify-between border-y border-color-separator p-5 tablet:flex-col tablet:justify-center tablet:border-x tablet:border-y-0 tablet:p-0">
          <div className="flex flex-col justify-center tablet:items-center">
            <p className="text-[18px] font-700 text-blue-600">
              {t("txt_save_budget")}
            </p>
            <p className="hidden text-[36px] font-700 text-blue-700 tablet:block">
              10x
            </p>
            <p className="text-[16px] font-400 text-blue-500">
              {t("txt_than_freelancers")}
            </p>
          </div>
          <p className="text-[28px] font-700 text-blue-700 tablet:hidden">
            10x
          </p>
        </div>
        <div className="flex flex-1 flex-row items-center justify-between p-5 tablet:flex-col tablet:justify-center tablet:p-0">
          <div className="flex flex-col justify-center tablet:items-center">
            <p className="text-[18px] font-700 text-blue-600">
              {t("txt_save_time")}
            </p>
            <p className="hidden text-[36px] font-700 text-blue-700 tablet:block">
              5h
            </p>
            <p className="text-[16px] font-400 text-blue-500">
              {t("txt_on_each_video")}
            </p>
          </div>
          <p className="text-[28px] font-700 text-blue-700 tablet:hidden">
            5h
          </p>
        </div>
      </div>
    </div>
  )
}

const AllAchievements = () => {
  return (
    <>
      <Desktop>
        <ContainerDesktop>
          <Achievement type="customers" />
          <Achievement type="spend" />
          <Achievement type="time" />
        </ContainerDesktop>
      </Desktop>

      <Tablet>
        <ContainerTablet>
          <Achievement tablet type="customers" />
          <Achievement tablet type="spend" />
          <Achievement tablet type="time" />
        </ContainerTablet>
      </Tablet>

      <Mobile>
        <ContainerMobile>
          <AchievementMobile type="customers" />
          <AchievementMobile type="spend" />
          <AchievementMobile type="time" />
        </ContainerMobile>
      </Mobile>
    </>
  )
}

export default AllAchievements
